var $ = require('jquery');
var bodymovin = require('bodymovin');


$('.child-img .line-art').each(function () {
  var that = this;
  
  setTimeout(function () {
    bodymovin.loadAnimation({
      container: that,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: '/js/home/' + $(that).attr('data-animation') + '.json'
    });
  }, 750);
});


$('.centers-for-excellence .icon-container span[data-animation]').each(function () {
  $(this).prev('img').hide();
  
  var animation = bodymovin.loadAnimation({
    container: this,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: '/js/home/' + $(this).attr('data-animation') + '.json'
  });
  
  animation.addEventListener('data_ready', function () {
    animation.goToAndStop(76, true);
  });
  
  $(this).closest('a').on('mouseenter', function () {
    if (animation.isPaused) {
      animation.goToAndPlay(0, true);
    }
  });
});
